<template>
  <div class="contact-form u-d-f u-jc-c u-fw-wrap-md">
    <div class="contact-form-left">
      <!-- <ClientOnly> -->
      <div v-if="!successForm" class="contact-form-form">
        <form ref="contactForm" @submit.prevent="submit">
          <h3 class="u-p-b">
            {{ tld !== 'FR' ? $t('contactForm.form.getInTouch') : $t('contactForm.form.title') }}
          </h3>

          <!-- CONTACT BLOCK -->

          <div class="contact-form-form-subtitle u-p-b">
            <span>{{ $t('contactForm.form.contactDetails') }}</span>
          </div>

          <div class="contact-form-form-input-wrapper u-d-f u-f-gap-md">
            <InputField
              ref="lastName"
              v-model="form.last_name"
              input-type="text"
              :placeholder-value="$t('fields.lname')"
              :field-title="$t('fields.lname')"
              :class-variant="'bg-grey radius-sm full-width u-m-b-less'"
              :input-rules="['required']"
            >
              <template #icon>
                <div class="icon-user icon-large u-bg-dark-blue" />
              </template>
            </InputField>

            <InputField
              ref="firstName"
              v-model="form.first_name"
              input-type="text"
              :placeholder-value="$t('fields.fname')"
              :field-title="$t('fields.fname')"
              :class-variant="'bg-grey radius-sm full-width u-m-b-less'"
              :input-rules="['required']"
            >
              <template #icon>
                <div class="icon-user icon-large u-bg-dark-blue" />
              </template>
            </InputField>
          </div>

          <div class="contact-form-form-input-wrapper u-d-f u-f-gap-md">
            <InputField
              ref="email"
              v-model="form.email"
              input-type="text"
              :placeholder-value="$t('contactForm.form.email')"
              :field-title="$t('contactForm.form.email')"
              :class-variant="'bg-grey radius-sm full-width u-m-b-less'"
              :input-rules="['required', 'email']"
            >
              <template #icon>
                <div class="icon-email icon-large u-bg-dark-blue" />
              </template>
            </InputField>

            <InputPhone
              id="phone"
              ref="phone"
              v-model="phoneNumber"
              :required="true"
              class="sp-input-field bg-grey radius-sm full-width u-m-b-less"
              @is-phone-invalid="isPhoneInvalid = $event"
            />
          </div>

          <!-- COMPANY BLOCK -->

          <div class="contact-form-form-subtitle u-p-b u-p-t-lg">
            <span>{{ $t('contactForm.form.yourCompany') }}</span>
          </div>

          <div class="contact-form-form-input-wrapper u-d-f u-f-gap-md u-p-b">
            <InputField
              ref="companyName"
              v-model="form.name"
              input-type="text"
              :placeholder-value="$t('fields.companyName')"
              :field-title="$t('fields.companyName')"
              :class-variant="'bg-grey radius-sm full-width u-m-b-less'"
              :input-rules="form[localCompanyCode.toLowerCase()]?.length ? ['required'] : []"
            >
              <template #icon>
                <div class="icon-building icon-large u-bg-dark-blue" />
              </template>
            </InputField>

            <div
              v-show="tld === 'FR'"
              class="contact-form-form-departments"
              @mouseleave="showDepartmentsResult = false"
            >
              <InputField
                v-model="searchDepartmentsInput"
                input-type="text"
                :placeholder-value="$t('contactForm.form.areaCode')"
                :field-title="$t('contactForm.form.areaCode')"
                :class-variant="'bg-grey radius-sm full-width u-m-b-less clearable-black'"
                clearable
                @click="searchDepartments"
                @clear="clearSearchDepartments"
              >
                <template #icon>
                  <div class="icon-localisation icon-large u-bg-dark-blue" />
                </template>
              </InputField>
              <div v-if="showDepartmentsResult" class="departments-search-result">
                <div
                  v-for="(department, i) in cloneDepartments"
                  :key="i"
                  class="departments-search-result-items"
                  @click.stop="selectDepartment(department)"
                >
                  <img
                    v-if="department.num_dep === form.area_code"
                    alt="checkbox-on"
                    src="@/assets/icons/ico-checkbox-on.svg"
                  />
                  <img v-else alt="checkbox-off" src="@/assets/icons/ico-checkbox-off.svg" />
                  <p>{{ department.num_dep }} - {{ department.dep_name }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="contact-form-form-input-wrapper u-d-f u-f-gap-md u-p-b-xl">
            <InputField
              :ref="localCompanyCode"
              v-model="form[localCompanyCode.toLowerCase()]"
              input-type="text"
              :placeholder-value="localCompanyCode"
              :field-title="localCompanyCode"
              :class-variant="'bg-grey radius-sm full-width u-m-b-less'"
              :input-formatter="tld === 'FR' ? sirenFormatter : lengthFormater"
            >
              <template #icon>
                <div class="icon-building icon-large u-bg-dark-blue" />
              </template>
            </InputField>
            <InputField
              v-model="form.employee_count"
              input-type="text"
              :placeholder-value="$t('contactForm.form.employees')"
              :field-title="$t('contactForm.form.employees')"
              :class-variant="'bg-grey radius-sm full-width u-m-b-less'"
              :input-rules="['numeric']"
            >
              <template #icon>
                <div class="icon-user-group icon-large u-bg-dark-blue" />
              </template>
            </InputField>
          </div>

          <div v-show="tld === 'FR'" class="contact-form-form-input-wrapper u-d-f u-f-gap-md u-p-b-xl u-p-t-sm">
            <div class="contact-form-form-product" @mouseleave="closeProductsResult">
              <SelectField
                ref="contactProduct"
                :selected-value="selectedProducts"
                :placeholder-value="$t('contactForm.form.productType')"
                :class-variant="'bg-grey radius-sm full-width u-m-b-less'"
                @click="showProductsResult = !showProductsResult"
              >
                <template #icon>
                  <div class="icon-help icon-large u-bg-dark-blue" />
                </template>
              </SelectField>
              <div v-if="showProductsResult" class="products-result">
                <div v-for="(product, i) in products" :key="i" class="products-result-items">
                  <label class="custom-checkbox-wrapper">
                    <input
                      type="checkbox"
                      :checked="form.product_type.includes(product.value)"
                      @change="selectProduct(product)"
                    />
                    <span>{{ product.label }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="contact-form-form-input-wrapper u-d-f u-f-gap-md" :class="tld === 'FR' ? 'u-p-b-sm' : 'u-p-b-lg'">
            <OriginForm
              ref="origin"
              v-model="form.origin_uid"
              icon="stock-pro"
              icon-color="dark-blue"
              :class-variant="'bg-grey radius-sm full-width u-m-b-less'"
            />
          </div>

          <div v-show="tld === 'FR'" class="contact-form-form-input-wrapper u-d-f u-f-gap-md u-p-b">
            <InputField
              v-model="form.comment"
              input-type="text"
              :placeholder-value="$t('contactForm.form.comment')"
              :field-title="$t('contactForm.form.comment')"
              :class-variant="'bg-grey radius-sm full-width u-m-b-less'"
            >
              <template #icon>
                <div class="icon-comment icon-large u-bg-dark-blue" />
              </template>
            </InputField>
          </div>

          <MainButton
            :label="$t('actions.beContacted')"
            :class-variant="'sp full-width u-m-b-less'"
            :disabled="isFormInvalid || isLoading"
            tag="button"
            type="submit"
          />
        </form>
      </div>
      <div v-else>
        <ProspectFormSuccess />
      </div>
      <!-- </ClientOnly> -->
    </div>

    <div class="contact-form-right">
      <h3 v-html="$t('contactForm.title')" />

      <div v-show="tld === 'FR'" class="contact-form-links u-p-y u-d-f u-ai-c">
        <a
          href="https://stock-pro.fr"
          :title="$t('footer.appStockProTitle')"
          target="_blank"
          @click="trackDownloadLink"
        >
          <ImageLazy
            src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/btn-stockpro-site-fr.svg"
            :alt="$t('footer.appStockProTitle')"
            :title="$t('footer.appStockProTitle')"
          />
        </a>

        <a
          href="https://apps.apple.com/fr/app/stock-pro/id1450359795"
          :title="$t('footer.appAppleTitle')"
          target="_blank"
          @click="trackDownloadLink"
        >
          <ImageLazy
            src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/btn-apple-store-fr.svg"
            :alt="$t('footer.appAppleTitle')"
            :title="$t('footer.appAppleTitle')"
            :width="139"
            :height="36"
          />
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.stockpro"
          :title="$t('footer.appGoogleTitle')"
          target="_blank"
          @click="trackDownloadLink"
        >
          <ImageLazy
            src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/btn-google-store-fr.svg"
            :alt="$t('footer.appGoogleTitle')"
            :title="$t('footer.appGoogleTitle')"
            :width="139"
            :height="36"
          />
        </a>
      </div>

      <div class="contact-form-icons">
        <div v-show="tld === 'FR'" class="sp-feature-label u-p-b-sm font-light text-m-l-sm">
          <img src="@/assets/icons/ico-scan-square-black.svg" />
          <p>{{ $t('contactForm.infos.digitizeAndStock') }}</p>
        </div>
        <div v-show="tld === 'FR'" class="sp-feature-label u-p-b-sm font-light text-m-l-sm">
          <img src="@/assets/icons/ico-worker-square-black.svg" />
          <p>{{ $t('contactForm.infos.manageStockTeam') }}</p>
        </div>
        <div v-show="tld === 'FR'" class="sp-feature-label u-p-b-sm font-light text-m-l-sm">
          <img src="@/assets/icons/ico-stock-square-black.svg" />
          <p>{{ $t('contactForm.infos.manageDelivery') }}</p>
        </div>
        <div class="sp-feature-label u-p-b-sm font-light text-m-l-sm">
          <img src="@/assets/icons/ico-data-clean-square-black.svg" />
          <p>{{ $t('contactForm.infos.cleanseAndEnrich') }}</p>
        </div>
        <div class="sp-feature-label u-p-b-sm font-light text-m-l-sm">
          <img src="@/assets/icons/ico-chain-square-black.svg" />
          <p>{{ $t('contactForm.infos.driveReCo') }}</p>
        </div>
        <div class="sp-feature-label u-p-b-sm font-light text-m-l-sm">
          <img src="@/assets/icons/ico-co2-square-black.svg" />
          <p>{{ $t('contactForm.infos.valueEffort') }}</p>
        </div>
      </div>

      <div class="contact-form-img u-p-t-lg">
        <ImageLazy src="https://d1l9hv9873iyuz.cloudfront.net/img-forms-desktop.webp" width="480" />
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'

import departments from '@/data/departments-region'
import { nextTick } from 'vue'

import InputFormatters from '@/helpers/InputFormatters'

import API from '@/mixins/sp-api-mixin'
import DownloadApp from '@/mixins/download-app-mixin'
import Mobile from '@/mixins/mobile-mixin'
import i18nMixin from '@/mixins/i18n-mixin'
import InputField from '@/components/InputField/InputField'
import SelectField from '@/components/SelectField/SelectField'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import ProspectFormSuccess from '@/components/Prospect/ProspectForm/ProspectFormSuccess'
import OriginForm from '@/components/Form/OriginForm/OriginForm'
import InputPhone from '@/components/InputField/InputPhone'

export default {
  name: 'ContactForm',
  components: {
    InputField,
    SelectField,
    MainButton,
    ProspectFormSuccess,
    OriginForm,
    InputPhone,
  },
  mixins: [API, DownloadApp, Mobile, i18nMixin],
  scrollToTop: true,
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      cloneDepartments: [],
      isFormInvalid: true,
      isPhoneInvalid: true,
      phoneNumber: null,
      isLoading: false,
      form: {
        last_name: null,
        first_name: null,
        email: null,
        phone_number: null,
        siren: null,
        name: null,
        employee_count: null,
        area_code: null,
        comment: null,
        product_type: [],
        origin_uid: null,
      },
      products: [
        { label: 'Gérer mes stocks', value: 'SUBSCRIPTION_IM' },
        { label: 'Vendre sur la marketplace', value: 'SUBSCRIPTION_PF' },
      ],
      searchDepartmentsInput: '',
      showDepartmentsResult: false,
      showProductsResult: false,
      successForm: false,
    }
  },
  computed: {
    selectedProducts() {
      return this.form.product_type.map(pt => this.products.find(p => p.value === pt).label).join(', ')
    },
  },
  watch: {
    form: {
      handler() {
        this.checkFormValidity()
      },
      immediate: true,
      deep: true,
    },
    searchDepartmentsInput() {
      this.searchDepartments()
    },
    isPhoneInvalid: {
      handler() {
        this.checkFormValidity()
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const { $currentUser } = useNuxtApp()
    if ($currentUser().user) {
      this.form.last_name = $currentUser().user.last_name
      this.form.first_name = $currentUser().user.first_name
      this.form.email = $currentUser().user.email
      this.phoneNumber = $currentUser().phone ? { phone_number: $currentUser().phone } : null

      nextTick(() => {
        this.checkFormValidity()
      })
    }
  },
  methods: {
    checkFormValidity() {
      for (const item of Object.entries(this.$refs)) {
        this.isFormInvalid = item[1].v$ && item[1].v$.$invalid
        if (this.isFormInvalid) break
      }
      this.isFormInvalid = this.isFormInvalid || this.isPhoneInvalid
    },

    clearForm() {
      this.form = {
        last_name: null,
        first_name: null,
        email: null,
        phone_number: null,
        siren: null,
        name: null,
        employee_count: null,
        area_code: null,
        comment: null,
        product_type: [],
        origin_uid: null,
      }
      this.phoneNumber = null
    },

    clearSearchDepartments() {
      this.showDepartmentsResult = false
      this.searchDepartmentsInput = ''
      this.form.area_code = null
    },

    closeProductsResult() {
      if (this.showProductsResult) {
        this.$refs.contactProduct.toggleFocus()
      }

      this.showProductsResult = false
    },

    searchDepartments() {
      if (this.searchDepartmentsInput.length >= 2) {
        this.cloneDepartments = departments.filter(
          d =>
            d.num_dep.toString().includes(this.searchDepartmentsInput?.toLowerCase()) ||
            d.dep_name
              ?.normalize('NFD')
              ?.replace(/\p{Diacritic}/gu, '')
              ?.toLowerCase()
              ?.includes(
                this.searchDepartmentsInput
                  ?.normalize('NFD')
                  ?.replace(/\p{Diacritic}/gu, '')
                  ?.toLowerCase()
              )
        )
        this.showDepartmentsResult = true
      } else {
        this.showDepartmentsResult = false
      }
    },

    selectDepartment(department) {
      this.searchDepartmentsInput =
        department.num_dep === this.form.area_code ? '' : department.num_dep + ' - ' + department.dep_name
      this.form.area_code = department.num_dep === this.form.area_code ? null : department.num_dep

      nextTick(() => {
        this.showDepartmentsResult = false
      })
    },

    selectProduct(product) {
      const idx = this.form.product_type?.indexOf(product.value)

      if (idx > -1) {
        this.form.product_type.splice(idx, 1)
      } else {
        this.form.product_type.push(product.value)
      }
    },

    sirenFormatter(input) {
      const { country } = useCurrentLocale()
      return InputFormatters.sirenFormatter(input, country?.localCompanyCodeLength)
    },
    lengthFormater(input) {
      const { country } = useCurrentLocale()
      return InputFormatters.lengthFormater(input, country?.localCompanyCodeLength)
    },

    submit() {
      if (!this.isFormInvalid) {
        this.isLoading = true

        const payload = { ...this.form, phone_number: this.phoneNumber }

        payload.product_type = this.form.product_type.length > 0 ? this.form.product_type.join() : null
        const { $api } = useNuxtApp()
        this.spRequest({
          req: $api().prospect.subscribeProspect(payload),
        })
          .then(() => {
            this.$refs.contactForm.reset()
            this.clearForm()
            this.successForm = true
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
.contact-form {
  padding: 60px;
  background-color: var(--bg-grey);
  overflow-x: hidden;

  @include mq($mq-sm) {
    padding: $spacing-md;
  }

  h3 > strong {
    color: var(--primary);
  }

  &-left,
  &-right {
    width: 480px;
  }

  &-left {
    background-color: var(--white);
    box-shadow: 0px 5px 30px rgba(5, 23, 47, 0.15);
    border-radius: $spacing-md;
    padding: $spacing-md;
    height: fit-content;
  }

  &-right {
    padding: $spacing-md;

    @include mq($mq-sm) {
      padding: 0;
    }
  }

  &-form {
    &-input-wrapper {
      @include mq($mq-sm) {
        flex-direction: column;
        grid-gap: 0;
        gap: 0;
      }
    }

    &-subtitle {
      font-weight: 500;
      font-size: pxToRem(15px);
      color: var(--steel-grey);
    }

    &-product {
      position: relative;
      width: 100%;

      .products-result {
        position: absolute;
        background-color: var(--bg-grey);
        z-index: 1;
        top: 38px;
        width: 100%;
        border-bottom-left-radius: $spacing-xxs;
        border-bottom-right-radius: $spacing-xxs;

        &-items {
          display: flex;
          align-items: center;
          gap: $spacing-sm;
          padding: 20px 10px;
          height: 30px;

          &:hover {
            background-color: var(--green-hover);
          }
        }
      }
    }

    &-departments {
      position: relative;
      width: 100%;

      .departments-search-result {
        position: absolute;
        background-color: var(--bg-grey);
        z-index: 1;
        top: 66px;
        width: 100%;
        border-bottom-left-radius: $spacing-xxs;
        border-bottom-right-radius: $spacing-xxs;
        max-height: 140px;
        overflow: scroll;

        &-items {
          display: flex;
          align-items: center;
          gap: $spacing-sm;
          padding: 0 10px;
          height: 30px;

          p {
            font-size: pxToRem(12px);
          }

          &:hover {
            background-color: var(--green-hover);
          }
        }
      }
    }
  }

  &-links {
    gap: $spacing-sm;

    @include mq($mq-sm) {
      gap: 0;
      justify-content: space-between;
    }
  }
  &-icons {
    @include mqmin($mq-md) {
      padding-top: $spacing-xl;
    }
  }

  &-img {
    img {
      margin-left: -40px;
    }

    @include mq($mq-md) {
      width: 100%;

      img {
        margin-left: 0;
        width: 100%;
      }
    }
  }
  .sp-feature-label {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &.font-light > p {
      font-weight: 400;
    }

    &.text-m-l-sm > p {
      margin-left: $spacing-sm;
    }
  }

  .sp-feature-label > p {
    font-family: Avenir;
    font-weight: 800;
    margin-left: 20px;
    color: var(--dark-blue);
    text-align: left;
  }
}
</style>
