<template>
  <div class="prospect-form-success">
    <p class="h3">
      {{ $t('message.ourTeamWillDo') }}
    </p>

    <div class="u-p u-d-f u-jc-c">
      <ImageLazy :src="'https://d3brsr9pdomwt0.cloudfront.net/illustrations/picto_validation.svg'" />
    </div>

    <p class="prospect-form-success-text" v-html="$t('message.askedContactSuccess')" />

    <div class="u-m-t u-d-f u-jc-c">
      <main-button
        :label="$t('actions.backToMkp')"
        :class-variant="'u-p-x-xl u-m-b-less'"
        tag="button"
        @click="$router.push('/')"
      />
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'ProspectFormSuccess',
  components: {
    MainButton,
  },
}
</script>

<style lang="scss">
.prospect-form-success-text {
  color: var(--steel-grey) !important;
  font-size: pxtToRem(15px) !important;
  font-weight: 500;
}
</style>
